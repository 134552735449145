* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background: #f0f2f5 !important;
}
#todo {
    .login {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.home {
    padding: 50px 3%;
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        h1 {
            font-size: 28px;
        }
    }
}

.ant-layout-sider {
    position: relative;
    min-width: 280px !important;
    background: none !important;
    transition: all 0.2s;
    padding: 50px 20px 20px;
}

.ant-layout-header {
    // background: #fff!important;
    .ant-menu {
        //background: #fff!important;
        display: flex;
        justify-content: center;
    }
}
.productos-card {
    .ant-card-body {
        display: flex;
        flex-wrap: wrap;
        .producto-card {
            margin: 10px;
            width: calc(25% - 20px);
            background: #f0f2f5;
            border-radius: 12px;
            border: 1px solid #dce6f7;
            .img-container {
                text-align: center;
                width: 100%;
                height: 250px;
                background: white;
                border-radius: 12px;
                border: 1px solid #dce6f7;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                }
            }
            .text-container{
                text-align: center;
                color: gray!important;
                padding: 5px;
                p{
                    font-weight: 600;
                    margin: 0;
                    font-size: 12px;
                    color: rgb(179, 179, 179)!important; 
                }
                b{
                    font-size: 11px;
                }
            }
            .lote-container{
                padding: 5px 8px;
                .group{
                    display: flex;
                    justify-content: space-between;
                    
                }
            }
            .lotcom{
                padding: 5px 10px;
                h2{
                    border-top: 1px solid rgba(0,0,0,0.3);
                    font-size: 15px;
                    margin: 0;
                    padding: 10px 0 0;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }
    }
}
